import React from "react"
import {Navbar} from "react-bulma-components"
import Dropdown from "./User/Dropdown"

export default ({authenticationState, logout}) => (
    <Navbar.Item dropdown hoverable>
        {(authenticationState.isSignedIn) && <>
            <Navbar.Link renderAs="div" arrowless>{authenticationState.user.givenName}</Navbar.Link>
            <Navbar.Dropdown right>
                <Dropdown authenticationState={authenticationState} logout={logout}/>
            </Navbar.Dropdown>
        </>}
    </Navbar.Item>
)