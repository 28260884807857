import React from "react"
import {Section, Container, Columns, Icon} from "react-bulma-components"
import {StaticQuery, graphql} from "gatsby"
import Header from "../../components/Header"
import Page from "../../components/Page"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSignInAlt} from "@fortawesome/pro-regular-svg-icons"

export default () => (
    <>
        <StaticQuery query={graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `} render={(data) => <Header title="Login" siteName={data.site.siteMetadata.title}/>}/>
        <Page>
            <Section>
                <Container>
                    <Columns className="is-centered">
                        <Columns.Column className="has-text-centered" narrow>
                            <a className="button is-large"
                               href={`${process.env.FEATHERSJS_EXTERNAL_URL}${process.env.FEATHERSJS_CAS_AUTHENTICATION_PATH}`}>
                                <Icon size="medium">
                                    <FontAwesomeIcon icon={faSignInAlt}/>
                                </Icon>
                                <span>CAS Login</span>
                            </a>
                        </Columns.Column>
                    </Columns>
                </Container>
            </Section>
        </Page>
    </>
)