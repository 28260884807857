import reduxifyServices from "feathers-redux"
import {client, authentication} from "../feathers"

const services = reduxifyServices(client, ['users']);

export function login() {
    return (dispatch) => {
        dispatch(authentication.authenticate())
    }
}

export function hasJWT() {
    return (dispatch) => {
        dispatch(authentication.authenticate())
    }
}

export function fetchUser(id) {
    return (dispatch) => {
        dispatch(services.users.get(id, {}))
    }
}

export function logout() {
    return (dispatch) => {
        dispatch(authentication.logout())
    }
}