import React from "react"
import {Footer, Container, Columns, Content} from "react-bulma-components"

export default () => (
    <footer>
        <Footer className="has-padding-15">
            <Container>
                <Columns breakpoint="mobile">
                    <Columns.Column className="has-text-left">
                        <Content>
                            <p>&copy; {new Date().getFullYear()} J. Reuben Clark Law School</p>
                        </Content>
                    </Columns.Column>
                    <Columns.Column className="has-text-right">
                        <Content>
                            <p>0.20230320.1</p>
                        </Content>
                    </Columns.Column>
                </Columns>
            </Container>
        </Footer>
    </footer>
)