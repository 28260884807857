import React from "react"
import Navbar from "../components/Page/Navbar"
import Footer from "../components/Page/Footer"

export default ({children}) => (
    <>
        <Navbar siteName="Carrel Picks"/>
        <main>{children}</main>
        <Footer/>
    </>
)