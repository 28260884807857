import React from "react"
import {Navbar} from "react-bulma-components"
import {Link} from "gatsby"

export default ({authenticationState, logout}) => (
    <>
        {(authenticationState && authenticationState.isSignedIn && authenticationState.user.permissions && authenticationState.user.permissions.includes("ADMIN")) && <>
            <Link className="navbar-item has-text-lighter" to="/admin">Admin</Link>
        </>}
        <Navbar.Item onClick={logout}>Logout</Navbar.Item>
    </>
)