import React from "react"
import {Section, Container, Columns, Icon} from "react-bulma-components"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons"

export default () => (
    <Section>
        <Container>
            <Columns className="is-centered" breakpoint="mobile">
                <Columns.Column className="has-margin-20" narrow>
                    <Icon size="large">
                        <FontAwesomeIcon icon={faSpinnerThird} size="4x" spin/>
                    </Icon>
                </Columns.Column>
            </Columns>
        </Container>
    </Section>
)