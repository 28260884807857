import React from "react"
import {Section, Container, Heading} from "react-bulma-components"
import Header from "./Header";
import Page from "./Page";

export default () => (
    <>
        <Header title="404" siteName="Carrel Picks"/>
        <Page>
            <Section>
                <Container>
                    <Heading renderAs="h2" size={1}>404</Heading>
                    <Heading renderAs="h4" size={3} subtitle>The requested page could not be found</Heading>
                </Container>
            </Section>
        </Page>
    </>
)