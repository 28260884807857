import React, {Component} from "react"
import {connect} from "react-redux"
import {Navbar} from "react-bulma-components"
import {Link} from "gatsby"
import PrimaryNavigation from "./Navbar/PrimaryNavigation"
import SecondaryNavigation from "./Navbar/SecondaryNavigation"
import {logout} from "../../actions/authentication"
import he from "he"

class NavbarComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navbarActive: false
        };
        this.navbarToggle = this.navbarToggle.bind(this);
    }

    navbarToggle() {
        this.setState({
            navbarActive: !this.state.navbarActive
        });
    }

    render() {
        const {reduxState: {authentication: authenticationState}} = this.props;

        return (
            <header>
                <Navbar color="primary" active={this.state.navbarActive}>
                    <Navbar.Brand>
                        <Link className="navbar-item" to="/">{he.decode(this.props.siteName)}</Link>
                        {(authenticationState.isSignedIn) &&
                        <Navbar.Burger textColor="lighter" onClick={this.navbarToggle}/>}
                    </Navbar.Brand>
                    {(authenticationState.isSignedIn) && <Navbar.Menu>
                        <Navbar.Container position="end">
                            <PrimaryNavigation/>
                        </Navbar.Container>
                        <Navbar.Container position="end">
                            <SecondaryNavigation authenticationState={authenticationState} logout={this.props.logout}/>
                        </Navbar.Container>
                    </Navbar.Menu>}
                </Navbar>
            </header>
        )
    }
}

function mapStateToProps(state) {
    return {
        reduxState: state
    }
}

export default connect(mapStateToProps, {logout})(NavbarComponent)